import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import fr from 'date-fns/locale/fr';
import enUS from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import { format, addMonths, isBefore, isSameDay, parseISO, addYears } from 'date-fns';
import './css/Calendar.css';

registerLocale('nl', nl);
registerLocale('fr', fr);
registerLocale('en-US', enUS);

const Calendar = ({ selectedDate, handleDateSelect, slotData, guests, language, fetchSlotData, allowedDates, clearSelectedSlot }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (allowedDates && allowedDates.length > 0) {
      const earliestAllowedDate = new Date(Math.min(...allowedDates.map(date => parseISO(date).getTime())));
      setCurrentMonth(earliestAllowedDate.getMonth() + 1);
      setCurrentYear(earliestAllowedDate.getFullYear());
    } else {
      setCurrentMonth(new Date().getMonth() + 1);
      setCurrentYear(new Date().getFullYear());
    }
  }, [allowedDates]);

  useEffect(() => {
    fetchSlotData(currentYear, currentMonth);
  }, [currentYear, currentMonth, fetchSlotData]);

  const handleMonthChange = (date) => {
    const newMonth = date.getMonth() + 1;
    setCurrentMonth(newMonth);
    setCurrentYear(date.getFullYear());
    fetchSlotData(date.getFullYear(), newMonth);
  };

  const shiftLength = window.shiftLength || 4;

  const checkConsecutiveSlots = (availableTimeslots, guests) => {
    const slots = availableTimeslots.map(Number);
    for (let i = 0; i < slots.length; i++) {
      let isValid = true;
      for (let j = 0; j < shiftLength; j++) {
        if (!slots.includes(slots[i] + j) || slotData[slots[i] + j] < guests) {
          isValid = false;
          break;
        }
      }
      if (isValid) return true;
    }
    return false;
  };

  
  const countAtLeastOneShiftAvailable = (guests, date) => {
    const intervals = ['M', 'A', 'E'];
  
    // Check if data for the date exists in both booking and capacity data
    if (!window.booking_data[date] || !window.capacity_data[date]) {
      // If data for the date is not available, return false or handle it as needed
      return false;
    }
  
    for (const i of intervals) {
      // Check if interval exists in both booking and capacity data for the given date
      if (window.booking_data[date][i] !== undefined && window.capacity_data[date][i] !== undefined) {
        if (window.booking_data[date][i] + guests <= window.capacity_data[date][i]) {
          return true;
        }
      }
    }
  
    // If no available shifts found, return false
    return false;
  };
  

  const checkAvailability = (availableTimeslots, guests, date) => {
    if (window.capacityPerShift == true) {
      window.availableTimeslots = availableTimeslots;
      return countAtLeastOneShiftAvailable(guests, date);
    }
    else {
      return checkConsecutiveSlots(availableTimeslots, guests);
    }
  };

  const dayClassName = (date) => {
    const today = new Date();
    const nextMonth = addMonths(new Date(currentYear, currentMonth - 1), 1);
    const previousMonth = addMonths(new Date(currentYear, currentMonth - 1), -1);

    if (isBefore(date, today) && !isSameDay(date, today)) {
      return 'react-datepicker__day--disabled';
    }
    if (date.getMonth() === nextMonth.getMonth() && date.getFullYear() === nextMonth.getFullYear()) {
      return 'react-datepicker__day--next-month';
    }
    if (date.getMonth() === previousMonth.getMonth() && date.getFullYear() === previousMonth.getFullYear()) {
      return 'react-datepicker__day--next-month';
    }
    if (selectedDate && date.getTime() === selectedDate.getTime()) {
      return 'react-datepicker__day--highlighted-custom';
    }

    const formattedDate = format(date, 'yyyy-MM-dd');
    const isAllowedDate = Array.isArray(allowedDates) && allowedDates.length > 0 
                          ? allowedDates.includes(formattedDate)
                          : true;

    if (!isAllowedDate) {
      return 'react-datepicker__day--disabled';
    }

    if (slotData[formattedDate]) {
      const slots = Object.keys(slotData[formattedDate]);
      let availableSlots = slots.filter(slot => slotData[formattedDate][slot] >= guests);

      if (window.capacityPerShift == true) {
        availableSlots = slots;
      }

      if (availableSlots.length === 0) {
        return 'react-datepicker__day--disabled';
      } else if (checkAvailability(availableSlots, guests, formattedDate)) {
        return 'react-datepicker__day--highlighted';
      }
    } else {
      // Day is unavailable (no slots exist)
      return 'react-datepicker__day--disabled';
    } 

    return 'react-datepicker__day--disabled';
  };

  const isDaySelectable = (date) => {
    const today = new Date();
    if (isBefore(date, today) && !isSameDay(date, today)) {
      return false;
    }
    const formattedDate = format(date, 'yyyy-MM-dd');
    const isAllowedDate = Array.isArray(allowedDates) && allowedDates.length > 0 
                          ? allowedDates.includes(formattedDate)
                          : true;

    if (!isAllowedDate) {
      return false;
    }

    const slots = Object.keys(slotData[formattedDate]);
    let availableSlots = Object.keys(slotData[formattedDate] || {}).filter(slot => slotData[formattedDate][slot] >= guests);

    if (window.capacityPerShift == true) {
      availableSlots = slots;
    }
    return checkAvailability(availableSlots, guests, formattedDate) || availableSlots.length === 0; // Allow selection of fully booked days
  };

  const handleDateClick = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    if (slotData[formattedDate]) {
      const availableSlots = Object.keys(slotData[formattedDate]).filter(slot => slotData[formattedDate][slot] >= guests);
      if (availableSlots.length === 0) {
        // Fully booked day clicked - clear the selected slot
        clearSelectedSlot();
      }
    }
    handleDateSelect(date);
  };

  return (
    <div className="react-datepicker-sizing">
      <DatePicker
        inline
        locale={language === 'NL' ? 'nl' : language === 'FR' ? 'fr' : 'en-US'}
        selected={selectedDate}
        onChange={(date) => {
          if (isDaySelectable(date)) {
            handleDateClick(date);
          }
        }}
        onMonthChange={handleMonthChange}
        dayClassName={dayClassName}
        className="custom-datepicker"
        minDate={allowedDates.length > 0 && window.validKey ? new Date(Math.min(...allowedDates.map(date => parseISO(date).getTime()))) : new Date()}
        maxDate={addYears(new Date(), 1)}  // Set max date to one year in the future
        startDate={new Date(currentYear, currentMonth - 1, 1)}
      />
    </div>
  );
};

export default Calendar;
