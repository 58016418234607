import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const DEBUG = false;

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  const queryParams = {
    restaurantNumber: params.get('restaurantNumber'),
    restaurantName: params.get('restaurantName'),
    waitingList: params.get('waitingList') === 'true',
    subdomainPrefix: params.get('subdomainPrefix'),
    restaurantMapsUrl: params.get('restaurantMapsUrl'),
    background: params.get('background'),
    title: params.get('title'),
    ownerPhone: params.get('ownerPhone'),
    maxGuests: params.get('maxGuests'),
    key: params.get('key'),
    pageType: params.get('pageType'),
    shiftLength: params.get('shiftLength'),
    capacityPerShift: params.get('capacityPerShift'),
    dates: params.get('dates') ? params.get('dates').split(',') : [],
    menus: params.get('menus') ? params.get('menus').split(',') : [],
    shifts: params.get('shifts') ? params.get('shifts').split(',').map(shift => {
      const [number, ...descriptionParts] = shift.split(':');
      const description = descriptionParts.join(':');
      return { number: parseInt(number, 10), description: description || '' };
    }) : [],
  };
  
  return queryParams;
}

const queryParams = getQueryParams();

window.restaurantNumber = queryParams.restaurantNumber;
window.subdomainPrefix = queryParams.subdomainPrefix;
window.restaurantMapsUrl = queryParams.restaurantMapsUrl;
window.background = queryParams.background;
window.title = queryParams.title;
window.allDates = queryParams.dates;
window.menu = queryParams.menus;
window.shift = queryParams.shifts;
window.key = queryParams.key;
window.pageType = queryParams.pageType;
window.restaurantName = queryParams.restaurantName;
window.waitingList = queryParams.waitingList;
window.shiftLength = parseInt(queryParams.shiftLength, 10);
window.capacityPerShift = queryParams.capacityPerShift === 'true';

console.log(window.capacityPerShift);

function convertToNumber(...inputs) {
  let result = 0;

  const processValue = (value) => {
    if (typeof value === 'number') {
      result += value;
    } else if (typeof value === 'string') {
      const charSum = Array.from(value).reduce((sum, char) => sum + char.charCodeAt(0), 0);
      result += charSum;
    } else if (Array.isArray(value)) {
      value.forEach(item => processValue(item));
    } else if (typeof value === 'object' && value !== null) {
      Object.values(value).forEach(item => processValue(item));
    } else if (value === null || value === undefined || typeof value === 'boolean') {
    } else {
    }
  };

  inputs.forEach(input => processValue(input));
  return result;
}

window.eventPage = window.pageType == "eventPage";
window.eventName = "__event";
window.waitingListSelection = false;

const expectedKey = convertToNumber(window.allDates, window.title, window.menu, window.shift, window.background, window.shiftLength, window.pageType);
window.validKey = expectedKey == window.key;

window.maxGuests = parseInt(queryParams.maxGuests, 10);
window.ownerPhone = queryParams.ownerPhone;
if (!window.validKey) {
  window.allDates = [];
  window.menu = [];
  window.shift = [];
  window.title = null;
  window.background = null;
  window.pageType = null;
}

if (DEBUG) {
  window.currentDomain = `http://1270.0.1:3000`;
  window.currentDomain = `https://${window.subdomainPrefix}.reservaties.net`;
} else {
  window.currentDomain = `https://${window.subdomainPrefix}.reservaties.net`;
  window.baseDomain = 'https://booking-system-euro-3c75b18a7c36.herokuapp.com/';
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
